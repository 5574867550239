import { useContext, useCallback, useState } from 'react';
import { AUTO_ASSIGN_ID, JOB_STATUS } from 'utils/constants';
import { formatUserDisplayName } from 'utils/formatters/user';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Session,
  JobDetailsContext,
  useKeyDown,
  usePropertyTime,
  ViewJobDetailsContext,
} from '@optii/shared';
import { JobDetails, JobForm } from '@optii/jobs';

import ViewJobDetails from 'components/shared/Job/ViewJobDetails';
import ViewJobDetailsHeader from 'components/shared/Job/ViewJobDetailsHeader';
import { LocationView } from 'components/shared/LocationView';
import AssetModals from 'components/Settings/AssetsSettings/AssetModals';
import { AssetsContextProvider } from 'components/Settings/AssetsSettings/contexts';
import dayjs from 'dayjs';
import { JOB_TYPES } from '../JobForm/JobForm.constants';

function isDone(job) {
  return [JOB_STATUS.completed, JOB_STATUS.cancelled].includes(job?.status);
}

function isOverdue(job) {
  if (!job?.doBy) return false;

  const { doBy } = job;
  const now = moment().unix();
  if (isDone(job)) return false;
  return doBy < now;
}

function getAssignment(job, SHOW_AUTO_ASSIGN_REPEATING_JOBS) {
  let value = `${job?.department.displayName}`;

  if (job?.assignee?.id >= 0 && !SHOW_AUTO_ASSIGN_REPEATING_JOBS) {
    value = value.concat(`, ${formatUserDisplayName(job?.assignee)}`);
  }
  return value;
}

function ViewJob(props) {
  const { queuedJobRequest, audit, disableBackdrop, isJobFormOpened, mode } =
    props;

  const [query, setQuery] = useQueryParams({
    edit: StringParam,
    editTemplate: StringParam,
    viewJob: StringParam,
    locationId: StringParam,
  });

  const [selectedLocationId, updateSelectedLocationId] = useState(null);
  const {
    job,
    close,
    setJob,
    asset,
    updateJob: [updateJob, { loading: updateLoading }],
    submitted,
    updateCheklistTask: [updateCheklistTask],
    refetch,
    toggleIsUpdateChecklistTaskLoading,
    loading,
    toggleSubmitted,
    isHousekeepingJob,
  } = useContext(JobDetailsContext);

  const SHOW_AUTO_ASSIGN_REPEATING_JOBS =
    job?.assignee?.id === AUTO_ASSIGN_ID &&
    Object.values([JOB_STATUS.in_progress, JOB_STATUS.done]).includes(
      job?.status,
    );

  const { timezone } = usePropertyTime();

  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['jobs', 'common', 'fields']);

  const timezoneOffset = dayjs().tz(timezone).utcOffset();

  let dueTime;

  if (timezoneOffset !== 0) {
    dueTime = job?.doBy ? dayjs.unix(job?.doBy).tz(timezone) : 'None';
  } else {
    dueTime = job?.doBy ? dayjs.unix(job?.doBy).utc() : 'None';
  }

  useKeyDown(27, close);

  const checklists =
    job?.checklistTemplate && job.status === 'queued'
      ? job?.checklistTemplate
      : job.checklists;

  const handleFileUpload = useCallback(
    async (UUID) => {
      try {
        await updateJob({
          variables: {
            id: job?.id,
            input: {
              attachments: [UUID, ...(job?.attachments || [])],
            },
          },
        });
        setJob((prev) => ({
          ...prev,
          attachments: [UUID, ...(prev.attachments || [])],
        }));
      } catch (err) {
        console.error('Failed to update job with new attachments', err);
        globalSnack({
          message: t('jobs:Failed to upload attachment'),
          error: true,
        });
      }
    },
    [globalSnack, job?.attachments, job?.id, setJob, t, updateJob],
  );

  const onLocationClick = useCallback((locationId) => {
    updateSelectedLocationId(locationId);
  }, []);

  const JOB_TYPE_OPTIONS = JOB_TYPES(t);
  const openNewJobDetails =
    job?.type === JOB_TYPE_OPTIONS.housekeeping.value ||
    job?.type === JOB_TYPE_OPTIONS.internal.value ||
    job?.type === JOB_TYPE_OPTIONS.guest.value;

  return (
    <ViewJobDetailsContext.Provider
      value={{
        job,
        close,
        checklists,
        setJob,
        asset,
        audit,
        submitted,
        toggleSubmitted,
        refetch,
        employees: ['heello'],
        updateChecklistTask: updateCheklistTask,
        updateLoading,
        toggleIsUpdateChecklistTaskLoading,
        loading,
        updateJob,
      }}
    >
      {openNewJobDetails ? (
        <JobDetails
          open
          onClose={close}
          disableBackdrop={disableBackdrop}
          legacyLogs={audit}
        />
      ) : (
        <ViewJobDetails
          overdue={isOverdue(job)}
          dueDate={dueTime}
          assignment={getAssignment(job, SHOW_AUTO_ASSIGN_REPEATING_JOBS)}
          description={
            <ViewJobDetailsHeader job={job} onLocationClick={onLocationClick} />
          }
          queuedJobRequest={queuedJobRequest}
          handleFileUpload={handleFileUpload}
          fulfilActions
          isHousekeepingJob={isHousekeepingJob}
        />
      )}

      {isJobFormOpened && (
        <JobForm
          mode={mode}
          onClose={() => {
            setQuery({ edit: '', editTemplate: '' });

            if (!query.viewJob && typeof close === 'function') {
              close();
            }
          }}
          open
        />
      )}

      <LocationView
        show={!!query.locationId}
        close={() => setQuery({ locationId: '' })}
        id={query.locationId}
      />
      <AssetsContextProvider>
        <AssetModals />
      </AssetsContextProvider>
    </ViewJobDetailsContext.Provider>
  );
}
export default ViewJob;
